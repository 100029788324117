import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SlideInSection from './SlideInSection';
import aboutShapeImg from '../assets/about-shape.png';
import aboutShapeImg2 from '../assets/aboutShapeImg2.png';


const About = () => {
    const { t } = useTranslation('common');
    const [textSize, setTextSize] = useState('text-md');
    const containerRef = useRef(null);

    const updateTextSize = useCallback(() => {
        const viewportWidth = window.innerWidth;

        if (viewportWidth >= 1920) {
            setTextSize('text-lg lg:text-xl');
        } else if (viewportWidth >= 768) {
            setTextSize('text-md sm:text-lg');
        } else {
            setTextSize('text-sm');
        }
    }, []);

    useEffect(() => {
        const debouncedUpdate = debounce(updateTextSize, 100);
        window.addEventListener('resize', debouncedUpdate);
        updateTextSize(); // Initial check

        return () => window.removeEventListener('resize', debouncedUpdate);
    }, [updateTextSize]);

    return (
        <section id="about" className="min-h-screen flex flex-col items-center w-full bg-gray-100 justify-center">
            <SlideInSection>
                <div
                    className="relative flex flex-col lg:flex-row items-center w-full bg-no-repeat bg-center bg-cover"
                    ref={containerRef}
                >
                    <div className="w-full lg:w-1/2 flex justify-center">
                        <img
                            src={aboutShapeImg}
                            alt="About Shape"
                            className="max-w-[90%] lg:max-w-[80%] h-auto object-contain"
                        />
                    </div>

                    {/* Yazı Alanı */}
                    <div className="w-full lg:w-1/2 px-6 lg:px-10 py-10 flex flex-col items-start">
                        <h2 className="text-2xl lg:text-4xl font-bold uppercase text-gray-800 mb-6 animate__animated animate__slideInUp" style={{animationDuration: "4s"}}>
                            {t('about.tag')}
                        </h2>
                        <p
                            className={`leading-relaxed text-gray-700 ${textSize} animate__animated animate__slideInUp`}
                            style={{
                                whiteSpace: 'pre-line',
                                maxWidth: '700px',
                                lineHeight: '1.75',
                                fontFamily: 'Verdana, sans-serif',
                                animationDuration: "2s"
                            }}
                        >
                            {t('about.info')}
                        </p>
                    </div>
                </div>
            </SlideInSection>
        </section>
    );
};

// Debounce helper function
const debounce = (fn: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => fn(...args), delay);
    };
};

export default About;
