import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Image from './components/Image';
import About from './components/About';
import Services from './components/Services';
import Projects from './components/Projects';
import Contact from './components/Contact';
import OurPartners from './components/Partners';
import ProjectsPage from './pages/Projects';
import CertificatesPage from './pages/Certificates';
import SlideInSection from "./components/SlideInSection";
import ImageTest from "./components/ImageTest";
import AboutTest from "./components/AboutTest";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={
                        <>
                            <Header/>
                                <Image />
                            <SlideInSection>
                                <About />
                            </SlideInSection>
                            <SlideInSection>
                                <Services />
                            </SlideInSection>
                                <Projects />
                            <SlideInSection>
                                <OurPartners />
                            </SlideInSection>
                                <Contact />
                        </>
                    } />
                    {/*<Route path="/projects/:language" element={<ProjectsPage />} />*/}
                    <Route path="/certificates/:language" element={<CertificatesPage />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;