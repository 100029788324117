import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "./Carousel.css"; 
import { Autoplay } from "swiper/modules";

import image from "../assets/projects-1.jpeg";
import image1 from "../assets/projects-2.jpeg";
import image2 from "../assets/projects-5.jpg";
import image3 from "../assets/projects-4.jpeg";

const Projects = () => {
    const { t } = useTranslation("common");

    return (
        <section id="projects" className="bg-white min-h-[93vh] flex flex-col monitor-24:mb-4" style={{backgroundColor: '#2F3246'}}>
            <div className="max-w-screen-2xl py-8 lg:px-6 sm:py-12 lg:pt-16 lg:pb-2">
                {/* Title Section */}
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-2xl sm:text-4xl leading-tight text-white dark:text-white">
                        {t("projects.tag")}
                    </h2>
                </div>

                {/* Swiper Carousel */}
                <div className="carousel-container mt-16">
                    <Swiper
                        modules={[Autoplay]} // Use Autoplay module
                        spaceBetween={30}
                        loop={true} // Enable infinite loop
                        autoplay={{
                            delay: 0, // Continuous scrolling
                            disableOnInteraction: false,
                        }}
                        speed={7000} // Control speed of slide transition
                        breakpoints={{
                            320: {
                                slidesPerView: 1, // Show 1 slide on small screens
                            },
                            640: {
                                slidesPerView: 2, // Show 2 slides on tablets
                            },
                            1024: {
                                slidesPerView: 3, // Show 3 slides on desktops
                            },
                        }}
                    >
                        {[image, image1, image2, image3].map((img, index) => (
                            <SwiperSlide key={index}>
                                <div className="space-y-4 border border-b-2 p-5 bg-white h-[60vh]">
                                    <img
                                        src={img}
                                        alt={`project ${index + 1}`}
                                        className="mx-auto w-full h-[35vh] object-cover rounded-lg shadow-mdh-[320px]"
                                    />
                                    <h3
                                        className="text-xl font-bold text-gray-900"
                                        style={{ color: "#2F3246" }}
                                    >
                                        {t(`projects.list.title-${index + 1}`)}
                                    </h3>
                                    <p
                                        className="text-base text-gray-500 dark:text-gray-400"
                                        style={{ color: "#656A8D" }}
                                    >
                                        {t(`projects.list.content-${index + 1}`)}
                                    </p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Projects;
