import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import homePage from "../assets/background-2-normal.jpg";
import topShapeImg from "../assets/top-shape-color2.png";
import bottomShapeImg from "../assets/bottom-shape-color2.png";
import Typed from 'typed.js';

const Image = () => {
  const sloganRef = useRef(null);
  const secondSloganRef = useRef(null);
  const imgRef = useRef(null);
  const { t } = useTranslation("common");


  return (
    <section className="flex items-center py-0 relative w-full pl-[72px] justify-between" style={{height:"90vh"}}>
  <div
    className="absolute inset-0 background-zoom"
    style={{
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: "100%",
      backgroundImage: `url(${homePage})`, 
      // filter: "blur(0.8px)",
      filter:"brightness(0.9)"
    }}
  ></div>

  <div
    className="top-shape"
    style={{
      backgroundImage: `url(${topShapeImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundPosition: "top left",
      width: "355px",
      height: "240px",
      position: "absolute",
      left: "0",
      top: "0",
      zIndex: 1,
      opacity:"0.9",
      display:"none"
    }}
  ></div>

  <div
    className="bottom-shape"
    style={{
      backgroundImage: `url(${bottomShapeImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundPosition: "bottom right",
      width: "480px",
      height: "571px",
      position: "absolute",
      right: "0",
      bottom: "0",
      zIndex: 1,
      opacity:"0.9"
    }}
  ></div>

<div className="relative w-[33%] h-[500px] flex items-start justify-center flex-col gap-2.5" style={{marginLeft:"40px", marginBottom:"100px"}}>
  {/* <div className="absolute inset-0 particle-effect"></div> */}

  <p
    style={{
      color: "white",
      fontFamily: "Playfair Display, serif",
      fontSize: "56px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      position: "relative",
      zIndex: 1,
      width:"600px",
      animationDuration: "2s",
      textShadow: "11px 2px 30px rgba(25, 27, 20, 2.7)",
      backgroundColor:"rgb(17 27 52 / 41%)",
      borderRadius:"16px",
      paddingLeft:"10px"
    }}
    className="w-[100%] text-white font-serif text-[56px] font-medium leading-normal relative z-[1] animate__animated animate__slideInLeft"
  >
    {t("slogan.text-1")}
  </p>
  {/* <p
    className="text-white font-h3-roboto-r-20px relative z-[1] animate__animated animate__slideInLeft"
    style={{ position: "relative", zIndex: 1, animationDuration: "2s", textShadow: "11px 2px 30px rgba(25, 27, 20, 2.7)", backgroundColor:"#4768b969", borderRadius:"16px" }}
  >
    {t("slogan.text-2")}
  </p> */}
</div>

</section>

  );
};

export default Image;


{/* <div className="relative w-[33%] h-[500px] flex items-start justify-center flex-col gap-2.5">
        <p
          className="w-[100%] text-white font-serif text-[56px] font-medium leading-normal relative z-[1] animate__animated animate__slideInRight"
          style={{ width: "550px" }}
        >
          {t("slogan.text-1")}
        </p>
        <p
          className="text-white font-h3-roboto-r-20px relative z-[1] animate__animated animate__slideInRight animate__delay-1s"
        >
          {t("slogan.text-2")}
        </p>
      </div> */}


  // useEffect(() => {
  //   const options1 = {
  //     strings: [t("slogan.text-1")],
  //     typeSpeed: 20,
  //     backSpeed: 50,
  //     backDelay: 1000,
  //     startDelay: 500,
  //     loop: false,
  //     onComplete: () => {
  //       typed2.start();
  //     },
  //   };

  //   const options2 = {
  //     strings: [t("slogan.text-2")],
  //     typeSpeed: 10,
  //     backSpeed: 50,
  //     backDelay: 1000,
  //     startDelay: 0,
  //     loop: false,
  //   };

  //   const typed1 = new Typed(sloganRef.current, options1);
  //   const typed2 = new Typed(secondSloganRef.current, options2);

  //   typed2.stop();


  //   return () => {
  //     typed1.destroy();
  //     typed2.destroy();
  //   };
  // }, [t]);
