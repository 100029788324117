import { useTranslation } from "react-i18next";
import service1 from '../assets/services-1.jpeg';
import service2 from '../assets/services-2.jpeg';
import service3 from '../assets/services-3.jpeg';
import service4 from '../assets/services-4.jpeg';
import service5 from '../assets/services-5.jpeg';
import service6 from '../assets/services-6.jpeg';
import React from "react";



const Services = () => {
    const { t } = useTranslation("common");

    return (
        <section
            className="flex flex-col items-center bg-gray-50 py-12 px-4 sm:px-8 lg:px-16"
            id="services"
            style={{paddingTop:"22px"}}
        >
            <h2 className="my-8 text-2xl sm:text-3xl lg:text-4xl font-medium text-gray-800">
                {t("services.tag")}
            </h2>
            <div className="container mx-auto">
                <div className="grid grid-cols- sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6" style={{placeItems:"center"}}>
                    {[service1, service2, service3, service4, service5, service6].map(
                        (service, index) => (
                            <div
                                key={index}
                                className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform hover:-translate-y-2 hover:shadow-2xl h-[330px]"
                                style={{
                                    width: "100%",
                                    maxWidth: "400px",
                                }}
                            >
                                <img
                                    src={service}
                                    alt={`Service ${index + 1}`}
                                    className="w-full h-48 sm:h-60 object-cover transition-transform duration-500 group-hover:scale-105"
                                />
                                <div className="p-4">
                                    <p className="text-gray-700 text-md font-semibold text-center mb-2">
                                        {t(`services.list.content-${index + 1}`)}
                                    </p>
                              
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </section>
    );
};

export default Services;
