import React from "react";
import image from "../assets/partner-1.png";
import image1 from "../assets/partner-2.png";
import image2 from "../assets/partner-3.png";
import image3 from "../assets/partner-4.png";
import image4 from "../assets/partner-5.png";
import image5 from "../assets/partner-7.jpeg";
import image6 from "../assets/partner-8.jpeg";
import image7 from "../assets/partner-99.png";
import image8 from "../assets/partner-10.png";
import image10 from "../assets/partner-122.png";
import image11 from "../assets/partner-13.png";
import image12 from "../assets/partner-21.png";
import image13 from "../assets/partner-23.png";
import image14 from "../assets/partner-24.png";
import image15 from "../assets/partner-26.png";
import image16 from "../assets/partner-28.png";
import image17 from "../assets/partner-29.png";
import { useTranslation } from "react-i18next";

const OurPartners = () => {
    const { t } = useTranslation("common");

    return (
        <section id="partners" className="bg-gray-100">
            <div className="py-8 lg:py-16 w-full">
                <h1 className="mb-8 lg:mb-16 text-3xl font-medium tracking-tight leading-tight text-center text-black-900 dark:text-black md:text-4xl">
                    {t("partners.tag")}
                </h1>
                <div className="flex flex-col gap-y-20">
                    {/* Row 1 */}
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:flex lg:flex-row xs:grid-cols-1 items-center justify-center lg:mx-32 lg:gap-x-36">
                        {[image, image1, image2, image3,image12, image4].map((imgSrc, index) => (
                            <div key={index} className="flex justify-center items-center">
                                <img
                                    src={imgSrc}
                                    alt={`partner-${index}`}
                                    className="w-36 h-24 md:w-36 md:h-16 lg:w-72 lg:h-24 object-contain"
                                    style={{
                                        transform:
                                            index === 3 ? "scale(2)" :
                                                index === 0 || index === 1 ? "scale(1.3)" :
                                                    "scale(1.6)"
                                    }}
                                />

                            </div>
                        ))}
                    </div>

                    {/* Row 2 */}
                    <div
                        className="grid grid-cols-2 md:grid-cols-3 lg:flex gap-y-16 lg:flex-row xs:grid-cols-1 items-center justify-center lg:gap-x-24 lg:mx-80">
                        {[ image5, image6, image16,  image8, image17, image7,].map((imgSrc, index) => (
                            <div key={index} className="flex justify-center items-center">
                                <img
                                    src={imgSrc}
                                    alt={`partner-${index + 5}`}
                                    className="w-36 h-24 md:w-36 md:h-16 lg:w-72 lg:h-24 object-contain"
                                    style={{ 
                                        transform:
                                        index === 5 ? "scale(1.1)" : index === 0 || index === 3 ? "scale(1.4)" :
                                        "scale(1.4)" }}
                                />
                            </div>
                        ))}
                    </div>

                    {/* Row 3 */}
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:flex lg:flex-row gap-y-6 xs:grid-cols-1 md:gap-x-10 lg:mx-60 lg:gap-x-24 items-center justify-center">
                        {[image14, image13, image11, image15, image10 ].map((imgSrc, index) => (
                            <div key={index} className="flex justify-center items-center pt-3">
                                <img
                                    src={imgSrc}
                                    alt={`partner-${index + 10}`}
                                    className="w-36 h-24 md:w-36 md:h-16 lg:w-72 lg:h-24 object-contain"
                                    style={{
                                    transform:
                                    index === 0 ? "scale(1.5)" :
                                        index === 1 || index === 2 ? "scale(1.4)" :
                                            "scale(1.3)" }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurPartners;
