import React, { useEffect, useState, useRef } from 'react';

interface SlideInSectionProps {
    children: React.ReactNode;
    className?: string; // Optional className prop
    onVisible?: () => void; // Optional callback for when the section becomes visible
}

const SlideInSection: React.FC<SlideInSectionProps> = ({ children, className, onVisible }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true); // Element is visible
                        onVisible?.(); // Call onVisible prop if provided
                    } else {
                        setIsVisible(false); // Element is not visible
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [onVisible]);

    return (
        <div
            ref={ref}
            className={`slide-in ${isVisible ? 'visible' : ''} ${className || ''}`} // Apply className prop
        >
            {children}
        </div>
    );
};

export default SlideInSection;