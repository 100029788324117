import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

// @ts-ignore
const LanguageSelector = ({ className }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('az');
    const [t, i18n] = useTranslation('common');

    const handleToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSelectLanguage = (language: any) => {
        setSelectedLanguage(language);
        setIsMenuOpen(false);
        i18n.changeLanguage(language);
    };

    const handleClickOutside = (event: any) => {
        if (event.target.closest('#languageButton') === null) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={`relative inline-block text-right ${className || ''}`}>
            <button
                id="languageButton"
                onClick={handleToggle}
                className="flex items-center px-4 py-2 text-sm font-medium text-gray-400 hover:text-indigo-500 focus:outline-none"
            >
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="elements">
                        <path
                            id="Ellipse 1574 (Stroke)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11 2.25C5.89137 2.25 1.75 6.39137 1.75 11.5C1.75 16.6086 5.89137 20.75 11 20.75C16.1086 20.75 20.25 16.6086 20.25 11.5C20.25 6.39137 16.1086 2.25 11 2.25ZM0.25 11.5C0.25 5.56294 5.06294 0.75 11 0.75C16.9371 0.75 21.75 5.56294 21.75 11.5C21.75 17.4371 16.9371 22.25 11 22.25C5.06294 22.25 0.25 17.4371 0.25 11.5Z"
                            fill="#2F3246"
                        />
                        <path
                            id="Vector (Stroke)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.5 5.75C10.9142 5.75 11.25 6.08579 11.25 6.5V7.12931L16 7.12931C16.4142 7.12931 16.75 7.4651 16.75 7.87931C16.75 8.29352 16.4142 8.62931 16 8.62931H14.0533C13.5141 10.2301 12.6127 11.729 11.5989 13.0649L13.0762 14.6008C13.3634 14.8993 13.3542 15.3741 13.0557 15.6612C12.7571 15.9484 12.2823 15.9391 11.9952 15.6406L10.6473 14.2394C9.72743 15.3081 8.76369 16.2583 7.90051 17.0521C7.59561 17.3324 7.12115 17.3126 6.84078 17.0077C6.56041 16.7028 6.5803 16.2283 6.8852 15.9479C7.76185 15.1418 8.72949 14.1835 9.6354 13.1145C9.40691 12.8276 9.17079 12.5167 8.95417 12.2163C8.61723 11.749 8.28915 11.2549 8.12735 10.9077C7.95237 10.5323 8.11487 10.0861 8.49031 9.91112C8.86575 9.73614 9.31195 9.89864 9.48694 10.2741C9.58228 10.4786 9.83277 10.8701 10.1708 11.3389C10.3064 11.5269 10.4498 11.7188 10.5931 11.9048C11.3554 10.8675 12.0108 9.76547 12.4571 8.62931H6C5.58579 8.62931 5.25 8.29352 5.25 7.87931C5.25 7.4651 5.58579 7.12931 6 7.12931H9.75V6.5C9.75 6.08579 10.0858 5.75 10.5 5.75Z"
                            fill="#2F3246"
                        />
                    </g>
                </svg>
                <span className="m-2">{selectedLanguage.toUpperCase()}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                    <path
                        d="M13 1.50005C13 1.50005 8.58107 7.49999 6.99995 7.5C5.41884 7.50001 1 1.5 1 1.5"
                        stroke="#2F3246"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            {isMenuOpen && (
                <div
                    className="origin-top-right absolute right-0 mt-2 w-16 focus:outline-none z-50 bg-white ring-1 ring-black"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                >
                    <div className="py-1 bg-transparent-800" role="none">
                        <button
                            onClick={() => handleSelectLanguage('az')}
                            className="block w-full text-left px-4 py-2 text-sm text-black hover:text-red-700"
                            role="menuitem"
                        >
                            {t('languages.az')}
                        </button>
                        <button
                            onClick={() => handleSelectLanguage('en')}
                            className="block w-full text-left px-4 py-2 text-sm text-black hover:text-red-700"
                            role="menuitem"
                        >
                            {t('languages.en')}
                        </button>
                        <button
                            onClick={() => handleSelectLanguage('ru')}
                            className="block w-full text-left px-4 py-2 text-sm text-black hover:text-red-700"
                            role="menuitem"
                        >
                            {t('languages.ru')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LanguageSelector;
