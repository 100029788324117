import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import Logo from './Logo';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const { t } = useTranslation('common');

    const handleToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            let yOffset = -70;
            if (window.innerWidth < 768) {
                yOffset = -300;
            }
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        setIsMenuOpen(false);
        setActiveMenuItem(id);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsMenuOpen(false);
        setActiveMenuItem('');
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsVisible(window.scrollY > 200);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['about', 'services', 'projects', 'partners', 'contact'];
            let currentActive = '';
            
            sections.forEach((id) => {
                const element = document.getElementById(id);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    if (rect.top <= 70 && rect.bottom > 70) {
                        currentActive = id;
                    }
                }
            });
    
            if (currentActive !== activeMenuItem) {
                setActiveMenuItem(currentActive);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [activeMenuItem]);

    

    return (
        <header className="sticky top-0 bg-white px-4 lg:px-20" style={{ zIndex: 100 }}>
            <nav className="bg-white py-3 lg:py-5 flex flex-col lg:flex-row items-center justify-between gap-4">
                <div className="flex items-center justify-between w-full lg:w-auto">
                    <Logo />
                    {/* LanguageSelector for mobile/tablet */}
                    <LanguageSelector className="lg:hidden order-1" />
                    {/* Burger menu button */}
                    <button
                        onClick={handleToggle}
                        type="button"
                        className="lg:hidden inline-flex items-center p-2 text-gray-500 hover:text-gray-900 focus:outline-none order-2"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            {isMenuOpen ? (
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            ) : (
                                <path
                                    fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                />
                            )}
                        </svg>
                    </button>
                </div>
                {/* Navbar */}
                <div
                    className={`${
                        isMenuOpen ? 'flex' : 'hidden'
                    } flex-col lg:flex lg:flex-row lg:items-center w-full lg:w-auto gap-4 transition-transform duration-300`}
                >
                    <div className="flex flex-col lg:flex-row items-center gap-4">
                        <section
                            onClick={scrollToTop}
                            className={`py-2 lg:px-4 cursor-pointer text-gray-700 hover:text-red-700 ${
                                activeMenuItem === '' ? 'text-red-700' : ''
                            }`}
                        >
                            {t('header.home')}
                        </section>
                        <section
                            onClick={() => scrollToSection('about')}
                            className={`py-2 lg:px-4 cursor-pointer text-gray-700 hover:text-red-700 ${
                                activeMenuItem === 'about' ? 'text-red-700' : ''
                            }`}
                        >
                            {t('header.about')}
                        </section>
                        <section
                            onClick={() => scrollToSection('services')}
                            className={`py-2 lg:px-4 cursor-pointer text-gray-700 hover:text-red-700 ${
                                activeMenuItem === 'services' ? 'text-red-700' : ''
                            }`}
                        >
                            {t('header.mission')}
                        </section>
                        <Link
                            to={`/certificates/${i18next.language}`}
                            target="_blank"
                            className="py-2 lg:px-4 cursor-pointer text-gray-700 hover:text-red-700"
                        >
                            {t('header.certificates')}
                        </Link>
                        <section
                            onClick={() => scrollToSection('projects')}
                            className={`py-2 lg:px-4 cursor-pointer text-gray-700 hover:text-red-700 ${
                                activeMenuItem === 'projects' ? 'text-red-700' : ''
                            }`}
                        >
                            {t('header.projects')}
                        </section>
                        <section
                            onClick={() => scrollToSection('partners')}
                            className={`py-2 lg:px-4 cursor-pointer text-gray-700 hover:text-red-700 ${
                                activeMenuItem === 'partners' ? 'text-red-700' : ''
                            }`}
                        >
                            {t('header.partners')}
                        </section>
                        <section
                            onClick={() => scrollToSection('contact')}
                            className={`py-2 lg:px-4 cursor-pointer text-gray-700 hover:text-red-700 ${
                                activeMenuItem === 'contact' ? 'text-red-700' : ''
                            }`}
                        >
                            {t('header.contact')}
                        </section>
                    </div>
                </div>
                {/* LanguageSelector for desktop */}
                <LanguageSelector className="hidden lg:block" />
            </nav>
            <button
                type="button"
                className={`!fixed bottom-5 right-5 ${isVisible ? 'block' : 'hidden'} rounded-full bg-red-600 p-3 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg`}
                onClick={scrollToTop}
            >
                <span className="[&>svg]:w-4">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="3"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                        />
                    </svg>
                </span>
            </button>
        </header>
    );
};

export default Header;
