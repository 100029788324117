import React from 'react';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import certificate1 from '../assets/1-certificate.png';
import certificate2 from '../assets/2-certificate.png';
import certificate3 from '../assets/3-certificate.png';
import certificate4 from '../assets/4-certificate.png';
import certificate5 from '../assets/5-certificate.png';
import certificate6 from '../assets/6-certificate.png';
import certificate7 from '../assets/7-certificate.png';
import certificate8 from '../assets/8-certificate.png';

import pdf1 from '../assets/1-certificate.pdf';
import pdf2 from '../assets/2-certificate.pdf';
import pdf3 from '../assets/3-certificate.pdf';
import pdf4 from '../assets/4-certificate.pdf';
import pdf5 from '../assets/5-certificate.pdf';
import pdf6 from '../assets/6-certificate.pdf';
import pdf7 from '../assets/7-certificate.pdf';
import pdf8 from '../assets/8-certificate.pdf';
import Contact1 from '../components/Contact1';
import Header1 from "../components/Header1";
import CertificatesBanner from "../components/Certificates-Banner";

const CertificatesPage = () => {
    const certificates = [

        {id: 7, image: certificate7, pdf: pdf7},   
        {id: 4, image: certificate4, pdf: pdf4},
        {id: 6, image: certificate6, pdf: pdf6},
        {id: 8, image: certificate8, pdf: pdf8},
        {id: 5, image: certificate5, pdf: pdf5},
        {id: 3, image: certificate3, pdf: pdf3},
        {id: 1, image: certificate1, pdf: pdf1},
        {id: 2, image: certificate2, pdf: pdf2},
        
    ];

    const {language} = useParams();
    const {t, i18n} = useTranslation('common');

    React.useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language, i18n]);

    return (
        <>
            <Header1/>
            <CertificatesBanner/>
            <div className="min-h-screen pt-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-12">
                    <div className="grid gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {certificates.map((certificate) => (
                            <div
                                key={certificate.id}
                                onClick={() => window.open(certificate.pdf, "_blank")}
                                className="mt-6 bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 cursor-pointer"
                            >
                                <img src={certificate.image} alt="" className="w-full h-full"/>
                            </div>
                        ))}
                    </div>
                </div>
                <Contact1/>
            </div>
        </>
    );
};

export default CertificatesPage;
